"use client"

import { addPropertyControls, ControlType } from "framer"
import { CSSProperties, useState } from "react"
import { Equals, User } from "@phosphor-icons/react"

/**
 * Framer-specific annotations
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */

// -------------------- Constants

const plans = [
    { name: "Basic", commission: 725 },
    { name: "Standard", commission: 1225 },
    { name: "Premium", commission: 1475 },
]

// ---------- / ----------

const styles = {
    "appearance-none": { appearance: "none" },
    border: { borderWidth: "1px", borderStyle: "solid" },
    "cursor-pointer": { cursor: "pointer" },
    flex: { display: "flex" },
    "flex-col": { flexDirection: "column" },
    "font-medium": { fontWeight: 500 },
    "font-normal": { fontWeight: 400 },
    "font-semibold": { fontWeight: 600 },
    "gap-1": { gap: "4px" },
    "gap-2": { gap: "8px" },
    "gap-4": { gap: "16px" },
    "items-center": { alignItems: "center" },
    "justify-center": { justifyContent: "center" },
    "opacity-80": { opacity: 0.8 },
    "outline-none": { outlineStyle: "none" },
    "p-6": { padding: "24px" },
    "px-4": { paddingLeft: "16px", paddingRight: "16px" },
    "py-2": { paddingTop: "8px", paddingBottom: "8px" },
    "rounded-2xl": { borderRadius: "16px" },
    "rounded-xl": { borderRadius: "12px" },
    "shadow-md": {
        boxShadow:
            "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
    },
    "tabular-nums": { fontVariantNumeric: "tabular-nums" },
    "text-2xl": { fontSize: "24px", lineHeight: 1.3 },
    "text-center": { textAlign: "center" },
    "text-sm": { fontSize: "14px", lineHeight: 1.4 },
    "text-xl": { fontSize: "20px", lineHeight: 1.4 },
    "w-full": { width: "100%" },
} satisfies Record<string, CSSProperties>

// -------------------- Utils

const moneyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
})

function formatMoney(money: number) {
    return moneyFormatter.format(money / 100)
}

// -------------------- Helpers

function joinStyles(...keys: (keyof typeof styles | false)[]): CSSProperties {
    return keys.reduce(
        (prev, key) =>
            typeof key === "string" ? { ...prev, ...styles[key] } : prev,
        {}
    )
}

// -------------------- Functions

export default function AffiliateDemo(
    props: Readonly<{
        title: string
        subtitle: string
        inputLabel: string
        outputRecurrence: string
        backgroundColor: string
        foregroundColor: string
        secondaryColor: string
        primaryColor: string
    }>
) {
    const [input, setInput] = useState("100")

    const [planIndex, setPlanIndex] = useState(0)

    const referrals = Number(input)

    const plan = plans[planIndex]

    const earnings = Math.floor(referrals * plan.commission)

    return (
        <div
            style={{
                backgroundColor: props.backgroundColor,
                color: props.foregroundColor,
                ...joinStyles(
                    "p-6",
                    "rounded-2xl",
                    "shadow-md",
                    "flex",
                    "flex-col",
                    "items-center",
                    "justify-center",
                    "gap-4"
                ),
            }}
        >
            <h1 style={joinStyles("text-xl", "font-medium", "text-center")}>
                {props.title}

                <br />

                <span
                    style={joinStyles("text-sm", "font-normal", "opacity-80")}
                >
                    {props.subtitle}
                </span>
            </h1>

            <div
                style={joinStyles("flex", "flex-col", "items-center", "gap-2")}
            >
                <div
                    style={joinStyles(
                        "flex",
                        "items-center",
                        "gap-1",
                        "opacity-80"
                    )}
                >
                    <User size={16} />

                    <label
                        style={joinStyles("text-sm", "font-normal")}
                        htmlFor="referrals"
                    >
                        {props.inputLabel}
                    </label>
                </div>

                <input
                    style={{
                        backgroundColor: "transparent",
                        color: props.foregroundColor,
                        ...joinStyles(
                            "w-full",
                            "px-4",
                            "py-2",
                            "border",
                            "rounded-xl",
                            "text-center",
                            "outline-none"
                        ),
                        borderColor: props.primaryColor,
                    }}
                    id="referrals"
                    type="number"
                    value={input}
                    onChange={(e) => {
                        const value = e.target.value

                        if (value.length > 6) return

                        setInput(value)
                    }}
                />
            </div>

            <div style={joinStyles("flex", "items-center")}>
                {plans.map(({ name }, i) => {
                    const isActive = i === planIndex

                    return (
                        <button
                            style={{
                                backgroundColor: "transparent",
                                color: props.foregroundColor,
                                ...joinStyles(
                                    "px-4",
                                    "py-2",
                                    "border",
                                    "rounded-xl",
                                    "text-sm",
                                    "font-medium",
                                    !isActive && "opacity-80",
                                    "cursor-pointer"
                                ),
                                borderColor: isActive
                                    ? props.secondaryColor
                                    : "transparent",
                            }}
                            onClick={() => setPlanIndex(i)}
                            key={i}
                        >
                            {name}
                        </button>
                    )
                })}
            </div>

            <Equals
                style={{
                    color: props.primaryColor,
                    ...styles["opacity-80"],
                }}
                size={20}
            />

            <span
                style={joinStyles(
                    "text-2xl",
                    "font-semibold",
                    "text-center",
                    "tabular-nums"
                )}
            >
                {formatMoney(earnings)}

                <br />

                <span
                    style={joinStyles("text-sm", "font-normal", "opacity-80")}
                >
                    {props.outputRecurrence}
                </span>
            </span>

            <style>{`#referrals::-webkit-outer-spin-button, #referrals::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0 }`}</style>
        </div>
    )
}

// -------------------- Framer-specific implementations

addPropertyControls(AffiliateDemo, {
    title: {
        title: "Title",
        type: ControlType.String,
        defaultValue: "Check potential earnings",
    },
    subtitle: {
        title: "Subtitle",
        type: ControlType.String,
        defaultValue: "with Dropie's affiliate program:",
    },
    inputLabel: {
        title: "Input label",
        type: ControlType.String,
        defaultValue: "Number of referrals",
    },
    outputRecurrence: {
        title: "Output recurrence",
        type: ControlType.String,
        defaultValue: "per month",
    },
    backgroundColor: {
        title: "Background color",
        type: ControlType.Color,
        defaultValue: "#FAFAFA",
    },
    foregroundColor: {
        title: "Foreground color",
        type: ControlType.Color,
        defaultValue: "#0A0A0A",
    },
    secondaryColor: {
        title: "Secondary color",
        type: ControlType.Color,
        defaultValue: "#D4D4D4",
    },
    primaryColor: {
        title: "Primary color",
        type: ControlType.Color,
        defaultValue: "#4F46E5",
    },
})
